// import React, { useState, useEffect } from 'react';
// import { Typography, Button } from '@mui/material';
// import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';
// import { useNavigate } from 'react-router-dom';
// const UserProfile = () => {
//   const navigate = useNavigate();
//   const [showEditProfile, setShowEditProfile] = useState(false);
//   const handlePage = () => {
//     setShowEditProfile(!showEditProfile);
//   };
//   const [formData, setFormData] = useState({
//     firstName: '',
//     lastName: '',
//     contactNo: '',
//     gender: '',
//     dob: '',
//     tob: '',
//     emailAddress: '',
//     language: '',
//     maritalStatus: '',
//     birthPlace: '',
//     address: "",
//   });

//   useEffect(() => {
//     const storedData = {
//       firstName: localStorage.getItem('firstName') || '',
//       lastName: localStorage.getItem('lastName') || '',
//       name: localStorage.getItem('userName') || '',
//       contactNo: localStorage.getItem('contactNo') || '',
//       gender: localStorage.getItem('gender') || '',
//       dob: localStorage.getItem('birthDate') || '',
//       tob: localStorage.getItem('birthTime') || '',
//       emailAddress: localStorage.getItem('email') || '',
//       language: localStorage.getItem('language') || '',
//       maritalStatus: localStorage.getItem('marital_status') || '',
//       birthPlace: localStorage.getItem('birthPlace') || '',
//       address: localStorage.getItem('addressLine1') || ''
//     };
//     setFormData(storedData);
//   }, []);
//   const handleChange = (e) => {
//     const { name, value } = e.target;

//     // Ensure only digits and handle length constraint
//     if (name === 'contactNo') {
//       if (/^\d*$/.test(value) && value.length <= 10) {
//         setFormData({
//           ...formData,
//           [name]: value
//         });
//       }
//     } else {
//       setFormData({
//         ...formData,
//         [name]: value
//       });
//     }
//   }

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const requestData = {
//       name: `${formData.firstName} ${formData.lastName}`,
//       contactNo: formData.contactNo,
//       birthDate: formData.dob,
//       birthTime: formData.tob,
//       birthPlace: formData.birthPlace,
//       gender: formData.gender,
//       language: formData.language,
//       marital_status: formData.maritalStatus,
//       email: formData.emailAddress,
//       addressLine1: formData.address
//     };
   
//     try {
//       const response = await fetch('https://app.astrovedaguru.com/api/user/update/416', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
         
//         },
//         body: JSON.stringify(requestData)
//       });

//       if (!response.ok) {
//         throw new Error('Failed to update user profile');
//       }
//       console.log('User profile updated successfully');
//       alert("your profile updated successfully.")
//       const str=requestData.name;
//      const result = str.split(" ");
//         localStorage.setItem("firstName", result[0]);
//         localStorage.setItem("lastName", result[1]);
//         localStorage.setItem("userName", str);
//         localStorage.setItem("email", requestData.email);
//         localStorage.setItem("birthDate", requestData.birthDate);
//         localStorage.setItem("birthTime", requestData.birthTime);
//         localStorage.setItem("birthPlace", requestData.birthPlace);
//         localStorage.setItem("contactNo", requestData.contactNo);
//         localStorage.setItem("gender", requestData.gender);
//         localStorage.setItem("marital_status", requestData.marital_status);
//         localStorage.setItem("language", requestData.language);
//         localStorage.setItem("addressLine1", requestData.addressLine1);
//     } catch (error) {
//       console.error('Error updating user profile:', error.message);  
//     }
//   }

//   return (
//     <>
//       <div className='main-contact'>
//         <div className='acharya-ajay'>
//           <Typography variant='h6' fontWeight='700' sx={{ alignItems: 'center', padding: '10px' }}>
//             <ArrowBackSharpIcon
//               fontWeight='bold'
//               sx={{ fontSize: '20px', marginRight: '10px', alignItems: 'center', cursor: 'pointer' }}
//               onClick={() => {
//                 navigate('/');
//               }}
//             />
//             My Profile
//           </Typography>
//         </div>
//         <div className='container mt-3'>
//           <div className='row'>
//             <div className='col-xl-3 col-lg-3 col-md-4 col-sm-12 mb-1'>
//               <div className='card card16'>
//                 <h6>My Profile</h6>
//               </div>
//             </div>
//             <div className='col-xl-9 col-lg-9 col-md-8 col-sm-12'>
//               <div className='card card18'>
//                 <br></br>
//                 <div className='wallet-container'>
//                   <h5>My Profile</h5>
//                   <Button variant='contained' sx={{ marginRight: '10px', fontSize: '13px', fontWeight: 'bold' }} onClick={handlePage}>
//                     {showEditProfile ? 'Back to Profile' : 'Edit Profile'}
//                   </Button>
//                 </div>

//                 {showEditProfile ? (
//                   <form className="user-form row p-3" onSubmit={handleSubmit}>
//                     <div className='col-xl-2 col-lg-2 col-md-1 col-12 mb-1'></div>
//                     <div className='col-xl-8 col-lg-8 col-md-10 col-12 mb-1'>
//                       <div className='row'>
//                         <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-1'>
//                           <div className='row'>
//                             <div className='col-12'>
//                               <Typography variant='h6'>First Name</Typography>
//                               <input type="text" className='form-control' name="firstName" value={formData.firstName} onChange={handleChange} placeholder='Your first name' />
//                             </div>
//                             <div className='col-12'>
//                               <Typography variant='h6'>Contact No</Typography>
//                               <input type="number" name="contactNo" value={formData.contactNo} onChange={handleChange} className='form-control' maxLength="10"/>
//                             </div>
//                             <div className='col-12'>
//                               <Typography variant='h6'>Gender</Typography>
//                               <div className='male'>
//                                 <div className='male-1'>
//                                   <p><input type="radio" name="gender" value="Female" checked={formData.gender === "Female"} onChange={handleChange} /> Female</p>
//                                 </div>
//                                 <div>
//                                   <p><input type="radio" name="gender" value="Male" checked={formData.gender === "Male"} onChange={handleChange} /> Male</p>
//                                 </div>
//                               </div>
//                             </div>
//                             <div className='col-12'>
//                               <Typography variant='h6'>Date Of Birth</Typography>
//                               <input type="date"  name="dob" value={formData.dob} onChange={handleChange} className='form-control' />
//                             </div>
//                             <div className='col-12'>
//                               <Typography variant='h6'>Time Of Birth</Typography>
//                               <input type="time"  name="tob" value={formData.tob} onChange={handleChange} className='form-control' />
//                             </div>
                            
//                           </div>
//                         </div>
//                         <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-1'>
//                           <div className='row'>
//                             <div className='col-12'>
//                               <Typography variant='h6'>Last Name</Typography>
//                               <input type="text" className='form-control'  name="lastName" value={formData.lastName} onChange={handleChange} placeholder='Your last name' />
//                             </div>
//                             <div className='col-12'>
//                               <Typography variant='h6'>Email Address</Typography>
//                               <input type="email"  name="emailAddress" value={formData.emailAddress} onChange={handleChange} className='form-control' placeholder='Enter your email' />
//                             </div>
//                             <div className='col-12'>
//                               <Typography variant='h6'>Language</Typography>
//                               <div className='male'>
//                                 <div className='male-1'>
//                                   <p><input type="radio" name="language" value="Hindi" checked={formData.language === "Hindi"} onChange={handleChange} /> Hindi</p>
//                                 </div>
//                                 <div>
//                                   <p><input type="radio" name="language" value="English"  checked={formData.language === "English"} onChange={handleChange} />English</p>
//                                 </div>
//                               </div>
//                             </div>
//                             <div className='col-12'>
//                               <Typography variant='h6'>Marital Status</Typography>
//                               <select className='form-control' name="maritalStatus" value={formData.maritalStatus} onChange={handleChange}>
//                                 <option value="">Select</option>
//                                 <option value="Unmarried" >Unmarried</option>
//                                 <option value="Married" >Married</option>
//                                 <option value="Others">Others</option>
//                               </select>
                             
//                             </div>

//                             <div className='col-12'>
//                               <Typography variant='h6'>Birth Place</Typography>
//                               <input type="text"  name="birthPlace" value={formData.birthPlace} onChange={handleChange} className='form-control' />
//                             </div>
//                           </div>
//                         </div>
//                         <div className='col-12'>
//                               <Typography variant='h6'>Address</Typography>
//                               <input type="text" name="address" value={formData.address} onChange={handleChange} className='form-control' />
//                             </div>
//                         <div className='col-12'>
//                           <button name="submit" className='submit-data' onSubmit={handleSubmit}>Submit</button>
//                         </div>
//                       </div>
//                     </div>
//                     <div className='col-xl-2 col-lg-2 col-md-1 col-12 mb-1'></div>
//                   </form>
//                 ) : (
//                   <div className='user'>
//                     <div>
//                       <Typography variant='h6'>Name:</Typography>
//                       <br></br>
//                       <Typography variant='h6'>Email:</Typography>
//                       <br></br>
//                       <Typography variant='h6'>Contact No:</Typography>
//                       <br></br>
//                       <Typography variant='h6'>Date Of Birth:</Typography>
//                       <br></br>
//                       <Typography variant='h6'>Time Of Birth:</Typography>
//                       <br></br>
//                       <Typography variant='h6'>Place:</Typography>
//                     </div>
//                     <div>
//                       <Typography variant='h6'>{formData.firstName} {formData.lastName}</Typography>
//                       <br></br>
//                       <Typography variant='h6'>{formData.emailAddress}</Typography>
//                       <br></br>
//                       <Typography variant='h6'>{formData.contactNo}</Typography>
//                       <br></br>
//                       <Typography variant='h6'>{formData.dob}</Typography>
//                       <br></br>
//                       <Typography variant='h6'>{formData.tob}</Typography>
//                       <br></br>
//                       <Typography variant='h6'>{formData.birthPlace}</Typography>
//                     </div>
//                   </div>
//                 )}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default UserProfile;

import React, { useState, useEffect } from 'react';
import { Typography, Button } from '@mui/material';
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import Toastify CSS
const UserProfile = () => {
  const navigate = useNavigate();
  const [showEditProfile, setShowEditProfile] = useState(false);
  const handlePage = () => {
    setShowEditProfile(!showEditProfile);
  };
  const [formData, setFormData] = useState({
    firstName: '',
    name: "",
    lastName: '',
    contactNo: '',
    gender: '',
    dob: '',
    tob: '',
    emailAddress: '',
    language: '',
    maritalStatus: '',
    birthPlace: '',
    address: "",
  });

  useEffect(() => {
    const storedData = {
      firstName: localStorage.getItem('firstName') || '',
      lastName: localStorage.getItem('lastName') || '',
      name: localStorage.getItem('userName') || '',
      contactNo: localStorage.getItem('contactNo') || '',
      gender: localStorage.getItem('gender') || '',
      dob: localStorage.getItem('birthDate') || '',
      tob: localStorage.getItem('birthTime') || '',
      emailAddress: localStorage.getItem('email') || '',
      language: localStorage.getItem('language') || '',
      maritalStatus: localStorage.getItem('marital_status') || '',
      birthPlace: localStorage.getItem('birthPlace') || '',
      address: localStorage.getItem('addressLine1') || ''
    };
    setFormData(storedData);
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Ensure only digits and handle length constraint
    if (name === 'contactNo') {
      if (/^\d*$/.test(value) && value.length <= 10) {
        setFormData({
          ...formData,
          [name]: value
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const requestData = {
      name: `${formData.firstName} ${formData.lastName}`,
      contactNo: formData.contactNo,
      birthDate: formData.dob,
      birthTime: formData.tob,
      birthPlace: formData.birthPlace,
      gender: formData.gender,
      language: formData.language,
      marital_status: formData.maritalStatus,
      email: formData.emailAddress,
      addressLine1: formData.address
    };
   
    try {
      const response = await fetch('https://app.astrovedaguru.com/api/user/update/416', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
         
        },
        body: JSON.stringify(requestData)
      });

      // if (!response.ok) {
      //   throw new Error('Failed to update user profile');
      // }
      // console.log('User profile updated successfully');
      // alert("your profile updated successfully.")

      if (!response.ok) 
      {
        toast.error("Failed to update profile");
      }else 
      {
        toast.success("Profile updated successfully");
      }
      const str=requestData.name;
     const result = str.split(" ");
        localStorage.setItem("firstName", result[0]);
        localStorage.setItem("lastName", result[1]);
        localStorage.setItem("userName", str);
        localStorage.setItem("email", requestData.email);
        localStorage.setItem("birthDate", requestData.birthDate);
        localStorage.setItem("birthTime", requestData.birthTime);
        localStorage.setItem("birthPlace", requestData.birthPlace);
        localStorage.setItem("contactNo", requestData.contactNo);
        localStorage.setItem("gender", requestData.gender);
        localStorage.setItem("marital_status", requestData.marital_status);
        localStorage.setItem("language", requestData.language);
        localStorage.setItem("addressLine1", requestData.addressLine1);
    } catch (error) {
      console.error('Error updating user profile:', error.message);  
    }
  }

  return (
    <>
      <div className='main-contact'>
        <div className='acharya-ajay'>
          <Typography variant='h6' fontWeight='700' sx={{ alignItems: 'center', padding: '10px' }}>
            <ArrowBackSharpIcon
              fontWeight='bold'
              sx={{ fontSize: '20px', marginRight: '10px', alignItems: 'center', cursor: 'pointer' }}
              onClick={() => {
                navigate('/');
              }}
            />
            My Profile
          </Typography>
        </div>
        <div className='container mt-3'>
          <div className='row'>
            <div className='col-xl-3 col-lg-3 col-md-4 col-sm-12 mb-1'>
              <div className='card card16'>
                <h6>My Profile</h6>
              </div>
            </div>
            <div className='col-xl-9 col-lg-9 col-md-8 col-sm-12'>
              <div className='card card18'>
                <br></br>
                <div className='wallet-container'>
                  <h5>My Profile</h5>
                  <Button variant='contained' sx={{ marginRight: '10px', fontSize: '13px', fontWeight: 'bold', backgroundColor:"#d73878" }} onClick={handlePage}>
                    {showEditProfile ? 'Back to Profile' : 'Edit Profile'}
                  </Button>
                </div>

                {showEditProfile ? (
                  <form className="user-form row p-3" onSubmit={handleSubmit}>
                    {/* <div className='col-xl-2 col-lg-2 col-md-1 col-12 mb-1'></div> */}
                    <div className='col-xl-12 col-lg-12 col-md-10 col-12 mb-1'>
                      <div className='row'>
                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-1'>
                          <div className='row'>
                            <div className='col-12'>
                              <Typography variant='h6'>First Name</Typography>
                              <input type="text" className='form-control' name="firstName" value={formData.name} onChange={handleChange} placeholder='Your first name' />
                            </div>
                            <div className='col-12 mt-1'>
                              <Typography variant='h6'>Contact No</Typography>
                              <input type="number" name="contactNo" value={formData.contactNo} onChange={handleChange} className='form-control' maxLength="10"/>
                            </div>
                            <div className='col-12 mt-1'>
                              <Typography variant='h6'>Gender</Typography>
                              <div className='male'>
                                  <p><input type="radio" name="gender" value="Female" checked={formData.gender === "Female"} onChange={handleChange} /> Female</p>
                                  <p style={{marginLeft:"50px"}}><input type="radio" name="gender" value="Male" checked={formData.gender === "Male"} onChange={handleChange} /> Male</p> 
                              </div>
                            </div>
                            <div className='col-12'>
                              <Typography variant='h6'>Date Of Birth</Typography>
                              <input type="date"  name="dob" value={formData.dob} onChange={handleChange} className='form-control' />
                            </div>
                            <div className='col-12 mt-1'>
                              <Typography variant='h6'>Time Of Birth</Typography>
                              <input type="time"  name="tob" value={formData.tob} onChange={handleChange} className='form-control' />
                            </div>          
                          </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-1'>
                          <div className='row'>
                            <div className='col-12'>
                              <Typography variant='h6'>Last Name</Typography>
                              <input type="text" className='form-control'  name="lastName" value={formData.lastName} onChange={handleChange} placeholder='Your last name' />
                            </div>
                            <div className='col-12 mt-1'>
                              <Typography variant='h6'>Email Address</Typography>
                              <input type="email"  name="emailAddress" value={formData.emailAddress} onChange={handleChange} className='form-control' placeholder='Enter your email' />
                            </div>
                            <div className='col-12 mt-1'>
                              <Typography variant='h6'>Language</Typography>
                              <div className='male'> 
                                  <p><input type="radio" name="language" value="Hindi" checked={formData.language === "Hindi"} onChange={handleChange} /> Hindi</p>
                                  <p style={{marginLeft:"50px"}}><input type="radio" name="language" value="English"  checked={formData.language === "English"} onChange={handleChange} />English</p>  
                              </div>
                            </div>
                            <div className='col-12'>
                              <Typography variant='h6'>Marital Status</Typography>
                              <select className='form-control' name="maritalStatus" value={formData.maritalStatus} onChange={handleChange}>
                                <option value="">Select</option>
                                <option value="Unmarried" >Unmarried</option>
                                <option value="Married" >Married</option>
                                <option value="Others">Others</option>
                              </select>
                             
                            </div>

                            <div className='col-12 mt-1'>
                              <Typography variant='h6'>Birth Place</Typography>
                              <input type="text"  name="birthPlace" value={formData.birthPlace} onChange={handleChange} className='form-control' />
                            </div>
                          </div>
                        </div>
                        <div className='col-12 mt-1'>
                              <Typography variant='h6'>Address</Typography>
                              <input type="text" name="address" value={formData.address} onChange={handleChange} className='form-control' />
                            </div>
                        <div className='col-12'>
                          <button name="submit" className='submit-data' onSubmit={handleSubmit}>Submit</button>
                        </div>
                        <ToastContainer />
                      </div>
                    </div>
                    {/* <div className='col-xl-2 col-lg-2 col-md-1 col-12 mb-1'></div> */}
                  </form>
                ) : (
                  <div className='user'>
                    <div>
                      <Typography variant='h6'>Name:</Typography>
                      <br></br>
                      <Typography variant='h6'>Email:</Typography>
                      <br></br>
                      <Typography variant='h6'>Contact No:</Typography>
                      <br></br>
                      <Typography variant='h6'>Date Of Birth:</Typography>
                      <br></br>
                      <Typography variant='h6'>Time Of Birth:</Typography>
                      <br></br>
                      <Typography variant='h6'>Place:</Typography>
                    </div>
                    <div>
                      <Typography variant='h6'>{formData.name} {formData.lastName}</Typography>
                      <br></br>
                      <Typography variant='h6'>{formData.emailAddress}</Typography>
                      <br></br>
                      <Typography variant='h6'>{formData.contactNo}</Typography>
                      <br></br>
                      <Typography variant='h6'>{formData.dob}</Typography>
                      <br></br>
                      <Typography variant='h6'>{formData.tob}</Typography>
                      <br></br>
                      <Typography variant='h6'>{formData.birthPlace}</Typography>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserProfile;

