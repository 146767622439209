// import React, { useState } from 'react';
// import { Typography, Button, TextField, Grid, InputAdornment } from '@mui/material';
// import DatePicker from 'react-datepicker';
// import "react-datepicker/dist/react-datepicker.css";
// import './Style.css';
// import axios from "axios";
// const KundliMatch = () => {
//   // const [boyData, setBoyData] = useState({
//   //   name: "",
//   //   day: 1,
//   //   month: "Jan",
//   //   year: 1990,
//   //   hour: 1,
//   //   minute: 1,
//   //   second: 0,
//   //   place: "New Delhi, Delhi, India",
//   // });
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [name  ,setName]   = useState("");
//   const [day   ,setDay]    = useState("");
//   const [month ,setMonth]  = useState("");
//   const [year  ,setYear]   = useState("");
//   const [hour  ,setHour]   = useState("");
//   const [minute  ,setMinute]   = useState("");
//   const [second  ,setSecond]   = useState("");
//   const [place   ,setPlace]   = useState("");

//   console.log('place',place);

//   const [girlData, setGirlData] = useState({
//     name: "",
//     day: 1,
//     month: "Jan",
//     year: 1992,
//     hour: 1,
//     minute: 1,
//     second: 0,
//     place: "New Delhi, Delhi, India",
//   });

//   const handleBoyInputChange = (e) => {
//     const { name, value } = e.target;
//     // setBoyData({ ...boyData, [name]: value });
//   };

//   const handleGirlInputChange = (e) => {
//     const { name, value } = e.target;
//     setGirlData({ ...girlData, [name]: value });
//   };

//   // const calculateMatch = () => {
//   //   // Placeholder match logic, for example, comparing places or birth dates.
//   //   alert('Match Calculated!');
//   // };

//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     try {

//       const requestData = {
//         name: name,

//       };

//       const token = localStorage.getItem("token");
//       // const response = await axios.post("https://app.astrovedaguru.com/api/kundali-test", {
//       //   name: name,
//       //   // contactNo: input2,
//       //   // email: input3,
//       //   // password: input4,
//       //   // birthDate: input5,
//       //   // birthTime: input6,
//       //   // birthPlace: input7,
//       // });
//       // const response = await fetch('https://app.astrovedaguru.com/api/kundali-test', {
//       //   method: 'POST',
//       //   headers: {
//       //     'Content-Type': 'application/json',
//       //     Authorization: `Bearer ${token}`

//       //   },
//       //   body: JSON.stringify(requestData)
//       // });

//       const response = await axios.post("https://app.astrovedaguru.com/api/kundali-test", {}, {
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${token}`
//         }
//       });
//       if (response.ok)
//         {
//         const data = response.data.data.Male;
//         console.log('ssss',response.data.data.Male);
//         setIsModalOpen(true); // Open the modal when data is received
//       }
//       // if (!response.ok) {
//       //   throw new Error('Failed to update user profile');
//       // }
//       // console.log('User profile updated successfully');
//       // alert("your profile updated successfully.")

//       // setInput1("");
//       // setInput2("");
//       // setInput3("");
//       // setInput4("");
//       // setInput5("");
//       // setInput6("");
//       // setInput7("");
//       alert("Signed up successfully");
//       // handleLoginInTo();
//       // showHide();
//     } catch (error) {
//       console.error("Error submitting data:", error);
//     }
//   };

//   return (
//     <div className="App">
//       <Typography variant="h4" gutterBottom>Kundali Matching</Typography>

//       <div className="form">
//         <Typography variant="h5">Boy's Details</Typography>
//         <Grid container spacing={2}>
//           <Grid item xs={12}>
//             <TextField
//               placeholder='Name'
//               label="Name"
//               name="name"
//               // type={'text'}
//               value={name}
//               onChange={(e) => setName(e.target.value)}
//               fullWidth
//               required
//             />
//           </Grid>
//           <Grid item xs={6}>
//             <TextField
//               label="Day"
//               type="number"
//               name="day"
//               // type={'number'}
//               value={day}
//               onChange={(e) => setDay(e.target.value)}
//               fullWidth
//               required
//             />
//           </Grid>
//           <Grid item xs={6}>
//             <TextField
//               label="Month"
//               name="month"
//               // type={'text'}
//               value={month}
//               onChange={(e) => setMonth(e.target.value)}
//               fullWidth
//               required
//             />
//           </Grid>
//           <Grid item xs={6}>
//             <TextField
//               label="Year"
//               type="Year"
//               name="year"
//               // type={'Year'}
//               value={year}
//               onChange={(e) => setYear(e.target.value)}
//               fullWidth
//               required
//             />
//           </Grid>
//           <Grid item xs={6}>
//             <TextField
//               label="Hour"
//               name="hour"
//               type="number"
//               // type={'number'}
//               value={hour}
//               onChange={(e) => setHour(e.target.value)}
//               fullWidth
//             />
//           </Grid>
//           <Grid item xs={6}>
//             <TextField
//               label="Minute"
//               type="number"
//               name="minute"
//               value={minute}
//               // onChange={handleBoyInputChange}
//               onChange={(e) => setMinute(e.target.value)}
//               fullWidth
//             />
//           </Grid>
//           <Grid item xs={6}>
//             <TextField
//               label="Second"
//               type="number"
//               name="second"
//               value={second}
//               // onChange={handleBoyInputChange}
//               onChange={(e) => setSecond(e.target.value)}
//               fullWidth
//             />
//           </Grid>
//           <Grid item xs={12}>
//             <TextField
//               label="Birth Place"
//               name="place"
//               value={place}
//               // type={'text'}
//               // onChange={handleBoyInputChange}
//               onChange={(e) => setPlace(e.target.value)}
//               fullWidth
//             />
//           </Grid>
//         </Grid>

//         <Typography variant="h5" style={{ marginTop: '30px' }}>Girl's Details</Typography>
//         <Grid container spacing={2}>
//           <Grid item xs={12}>
//             <TextField
//               label="Name"
//               name="name"
//               value={girlData.name}
//               onChange={handleGirlInputChange}
//               fullWidth
//             />
//           </Grid>
//           <Grid item xs={6}>
//             <TextField
//               label="Day"
//               type="number"
//               name="day"
//               value={girlData.day}
//               onChange={handleGirlInputChange}
//               fullWidth
//             />
//           </Grid>
//           <Grid item xs={6}>
//             <TextField
//               label="Month"
//               name="month"
//               value={girlData.month}
//               onChange={handleGirlInputChange}
//               fullWidth
//             />
//           </Grid>
//           <Grid item xs={6}>
//             <TextField
//               label="Year"
//               type="number"
//               name="year"
//               value={girlData.year}
//               onChange={handleGirlInputChange}
//               fullWidth
//             />
//           </Grid>
//           <Grid item xs={6}>
//             <TextField
//               label="Hour"
//               type="number"
//               name="hour"
//               value={girlData.hour}
//               onChange={handleGirlInputChange}
//               fullWidth
//             />
//           </Grid>
//           <Grid item xs={6}>
//             <TextField
//               label="Minute"
//               type="number"
//               name="minute"
//               value={girlData.minute}
//               onChange={handleGirlInputChange}
//               fullWidth
//             />
//           </Grid>
//           <Grid item xs={6}>
//             <TextField
//               label="Second"
//               type="number"
//               name="second"
//               value={girlData.second}
//               onChange={handleGirlInputChange}
//               fullWidth
//             />
//           </Grid>
//           <Grid item xs={12}>
//             <TextField
//               label="Birth Place"
//               name="place"
//               value={girlData.place}
//               onChange={handleGirlInputChange}
//               fullWidth
//             />
//           </Grid>
//         </Grid>

//         <Button
//           variant="contained"
//           color="primary"
//           onClick={handleSubmit}
//           style={{ marginTop: '30px' }}
//         >
//           Match Horoscope
//         </Button>
//       </div>

//       <Typography variant="h6" style={{ marginTop: '30px' }}>
//         Saved Matches
//       </Typography>
//       <Typography variant="body1">
//         Please login to check your saved horoscope!
//       </Typography>

//       <div className="footer">
//         <Typography variant="h6" style={{ marginTop: '30px' }}>
//           Connect with an Astrologer on Call or Chat for more details.
//         </Typography>
//       </div>
//     </div>
//   );
// };

// // Modal Component
// const Modal = ({ isOpen, onClose, data }) => {
//   if (!isOpen) return null;  // Don't render modal if not open

//   return (
//     <div style={modalOverlayStyles}>
//       <div style={modalStyles}>
//         <button onClick={onClose} style={closeButtonStyles}>
//           Close
//         </button>
//         <h2>Data from API</h2>
//         <table border="1" style={tableStyles}>
//           <thead>
//             <tr>
//               <th>Name</th>
//               <th>Day</th>
//               <th>Month</th>
//               <th>Year</th>
//               <th>Hour</th>
//               <th>Minute</th>
//               <th>Place</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td>eef</td>
//               <td>dsdf</td>
//               <td>dfdf</td>
//               <td>dfdf</td>
//               <td>dfd</td>
//               <td>df</td>
//               <td></td>
//             </tr>
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };
// // Modal styles
// const modalOverlayStyles = {
//   position: "fixed",
//   top: 0,
//   left: 0,
//   right: 0,
//   bottom: 0,
//   backgroundColor: "rgba(0, 0, 0, 0.5)",
//   display: "flex",
//   justifyContent: "center",
//   alignItems: "center",
//   zIndex: 1000,
// };

// const modalStyles = {
//   backgroundColor: "#fff",
//   padding: "20px",
//   borderRadius: "8px",
//   width: "600px",
//   maxHeight: "80vh",
//   overflowY: "auto",
//   position: "relative",
// };

// const closeButtonStyles = {
//   position: "absolute",
//   top: "10px",
//   right: "10px",
//   background: "#ff0000",
//   color: "#fff",
//   border: "none",
//   padding: "10px",
//   borderRadius: "50%",
//   cursor: "pointer",
// };

// const tableStyles = {
//   width: "100%",
//   borderCollapse: "collapse",
//   marginTop: "20px",
// };

import React, { useState } from "react";
import { Typography, Button, TextField, Grid } from "@mui/material";
import axios from "axios";
import "./Style.css";
import ClearIcon from "@mui/icons-material/Clear";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

// Modal Component
const Modal = ({ isOpen, onClose, data }) => {
  if (!isOpen) return null; // Don't render modal if not open

  return (
    <div style={modalOverlayStyles}>
      <div style={modalStyles}>
        <button onClick={onClose} style={closeButtonStyles}>
          <ClearIcon />
        </button>
        
        <h2 style={{color:"#d73878", fontSize:"20px", fontWeight:"bold"}}>Kundali Matching</h2>

        <TableContainer component={Paper} sx={{ border: '2px solid #f0df20' }}>
          <Table
            sx={{
              minWidth: 650,// Adds yellow border around the entire table
              borderCollapse: "collapse", // Ensures the borders don't overlap with cells
            }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="center" sx={{ fontWeight: "bold", border: "1px solid lightgray" }}>
                  Nakshatra
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold", border: "1px solid lightgray"  }}>
                Total
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold", border: "1px solid lightgray"  }}>
                  Rasi
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold", border: "1px solid lightgray"  }}>
                  RasiLord
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold", border: "1px solid lightgray"  }}>
                  Day
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold", border: "1px solid lightgray"  }}>
                  Month
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold", border: "1px solid lightgray"  }}>
                  Year
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold", border: "1px solid lightgray"  }}>
                  Hour
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold", border: "1px solid lightgray"  }}>
                  Minute
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold", border: "1px solid lightgray"  }}>
                  Place
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data ? (
                <TableRow>
                  <TableCell align="center" sx={{ fontWeight: "bold", color:"#f0df20" , border: "1px solid lightgray" }}>{data.Nakshatra}</TableCell>
                  {/* <TableCell align="center" sx={{ border: "1px solid lightgray" }}>{data.NakshatraPada}</TableCell> */}
                  <TableCell align="center" sx={{ border: "1px solid lightgray" }}>{data.Score}/{data.OutOfScore}</TableCell>
                  <TableCell align="center" sx={{ border: "1px solid lightgray" }}>{data.Rasi}</TableCell>
                  <TableCell align="center" sx={{ border: "1px solid lightgray" }}>{data.RasiLord}</TableCell>
                  <TableCell align="center" sx={{ border: "1px solid lightgray" }}>{data.day}</TableCell>
                  <TableCell align="center" sx={{ border: "1px solid lightgray" }}>{data.month}</TableCell>
                  <TableCell align="center" sx={{ border: "1px solid lightgray" }}>{data.year}</TableCell>
                  <TableCell align="center" sx={{ border: "1px solid lightgray" }}>{data.hour}</TableCell>
                  <TableCell align="center" sx={{ border: "1px solid lightgray" }}>{data.minute}</TableCell>
                  <TableCell align="center" sx={{ border: "1px solid lightgray" }}>{data.place}</TableCell>
                </TableRow>
              ) : (
                <TableRow>
                  <TableCell colSpan="7">Loading...</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <table border="1" style={tableStyles}>
          <thead>
            <tr>
              <th>Nakshatra</th>
              <th>NakshatraPada</th>
              <th>Rasi</th>
              <th>RasiLord</th>
              <th>Day</th>
              <th>Month</th>
              <th>Year</th>
              <th>Hour</th>
              <th>Minute</th>
              <th>Place</th>
            </tr>
          </thead>
          <tbody>
           
           
          </tbody>
        </table> */}
      </div>
    </div>
  );
};

// Modal styles
const modalOverlayStyles = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1000,
};

const modalStyles = {
  backgroundColor: "#fff",
  padding: "20px",
  borderRadius: "8px",
  width: "80%",
  maxHeight: "80vh",
  overflowY: "auto",
  position: "relative",
};

const closeButtonStyles = {
  position: "absolute",
  top: "-10px",
  right: "5px",
  background: "#f0df20",
  color: "#fff",
  border: "none",
  padding: "4px",
  borderRadius: "50%",
  cursor: "pointer",
};

const tableStyles = {
  width: "100%",
  borderCollapse: "collapse",
  marginTop: "20px",
};

// Main Component
const KundliMatch = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [name, setName] = useState("");
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [hour, setHour] = useState("");
  const [minute, setMinute] = useState("");
  const [second, setSecond] = useState("");
  const [place, setPlace] = useState("");

  // Girl's Data 

  const [gname, setGName] = useState("");
  const [gday, setGDay] = useState("");
  const [gmonth, setGMonth] = useState("");
  const [gyear, setGYear] = useState("");
  const [ghour, setGHour] = useState("");
  const [gminute, setGMinute] = useState("");
  const [gsecond, setGSecond] = useState("");
  const [gplace, setGPlace] = useState("");
  
  const [boyData, setBoyData] = useState({
    name: "",
    day: 1,
    month: "Jan",
    year: 1990,
    hour: 1,
    minute: 1,
    second: 0,
    place: "New Delhi, Delhi, India",
  });

  const [girlData, setGirlData] = useState({
    name: "",
    day: 1,
    month: "Jan",
    year: 1992,
    hour: 1,
    minute: 1,
    second: 0,
    place: "New Delhi, Delhi, India",
  });

  const handleBoyInputChange = (e) => {
    const { name, value } = e.target;
    setBoyData({ ...boyData, [name]: value });
  };

  const handleGirlInputChange = (e) => {
    const { name, value } = e.target;
    setGirlData({ ...girlData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const requestData = {
      name: name,
      day: day,
      month: month,
      year: year,
      hour: hour,
      minute: minute,
      second: second,
      place: place,
      gname:gname,
      gday:gday,
      gmonth:gmonth,
      gyear:gyear,
      ghour:ghour,
      ghour:ghour,
      gminute:gminute,
      gsecond:gsecond,
      gplace:gplace,
    };

    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        "https://app.astrovedaguru.com/api/kundali-test",
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data) {
        const dateString = response.data.data.Male.DOB;
        const date = new Date(dateString);
        // Get the year from the Date object
        // let year = date.getFullYear();
        // const month = date.getMonth() + 1; // getMonth() returns 0 for January, so we add 1
        // const day = date.getDate();
        // const hours = date.getHours();
        // const minutes = date.getMinutes();
        // const seconds = date.getSeconds();

        const dataArr = {
          Nakshatra: response.data.data.Male.Nakshatra,
          Rasi: response.data.data.Male.Rasi,
          RasiLord: response.data.data.Male.RasiLord,
          day: date.getDate(),
          month: date.getMonth() + 1,
          year: date.getFullYear(),
          hour: date.getHours(),
          minute: date.getMinutes(),
          second: date.getSeconds(),
          place: response.data.data.Male.Place,
          NakshatraPada: response.data.data.Male.NakshatraPada,
          Score: response.data.data.AshtakootMatchingResult[8].Score,
          OutOfScore: response.data.data.AshtakootMatchingResult[8].OutOfScore,
        };
        const data = dataArr; // Assuming this is the structure of the response
        setIsModalOpen(true); // Open the modal when data is received
        // console.log("API response data:", response.data.data.AshtakootMatchingResult[8].Score);
        console.log("API requestData :", requestData);
        setBoyData(data); // Store the received data for the boy in the state
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  return (
    <div className="App">
      <Typography variant="h4" gutterBottom>
        Kundali Matching
      </Typography>
        <form onSubmit={handleSubmit}>
          <div className="form">
            <Typography
              variant="h5"
              sx={{ marginBottom: "10px", color: "#d73878", fontWeight: "bold" }}
            >
              Boy's Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  placeholder="Name"
                  label="Name"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Day"
                  type="number"
                  name="day"
                  value={day}
                  onChange={(e) => setDay(e.target.value)}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Month"
                  name="month"
                  value={month}
                  onChange={(e) => setMonth(e.target.value)}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Year"
                  type="number"
                  name="year"
                  value={year}
                  onChange={(e) => setYear(e.target.value)}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Hour"
                  type="number"
                  name="hour"
                  value={hour}
                  onChange={(e) => setHour(e.target.value)}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Minute"
                  type="number"
                  name="minute"
                  value={minute}
                  onChange={(e) => setMinute(e.target.value)}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Second"
                  type="number"
                  name="second"
                  value={second}
                  onChange={(e) => setSecond(e.target.value)}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Birth Place"
                  name="place"
                  value={place}
                  onChange={(e) => setPlace(e.target.value)}
                  fullWidth
                  required
                />
              </Grid>
            </Grid>

            <Typography
              variant="h5"
              sx={{
                margin: "20px 0px 10px 0px",
                color: "#d73878",
                fontWeight: "bold",
              }}
            >
              Girl's Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Name"
                  name="name"
                  value={gname}
                  // onChange={handleGirlInputChange}
                  onChange={(e) => setGName(e.target.value)}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Day"
                  type="number"
                  name="day"
                  value={gday}
                  // onChange={handleGirlInputChange}
                  onChange={(e) => setGDay(e.target.value)}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Month"
                  name="month"
                  value={gmonth}
                  // onChange={handleGirlInputChange}
                  onChange={(e) => setGMonth(e.target.value)}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Year"
                  type="number"
                  name="year"
                  value={gyear}
                  // onChange={handleGirlInputChange}
                  onChange={(e) => setGYear(e.target.value)}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Hour"
                  type="number"
                  name="hour"
                  value={ghour}
                  // onChange={handleGirlInputChange}
                  onChange={(e) => setGHour(e.target.value)}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Minute"
                  type="number"
                  name="minute"
                  value={gminute}
                  // onChange={handleGirlInputChange}
                  onChange={(e) => setGMinute(e.target.value)}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Second"
                  type="number"
                  name="second"
                  value={gsecond}
                  // onChange={handleGirlInputChange}
                  onChange={(e) => setGSecond(e.target.value)}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Birth Place"
                  name="place"
                  value={gplace}
                  // onChange={handleGirlInputChange}
                  onChange={(e) => setGPlace(e.target.value)}
                  fullWidth
                  required
                />
              </Grid>
            </Grid>

            <div style={{ display: "flex", justifyContent: "center" }}>
              {/* <Button
                className="match-kundli-btn"
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                style={{ marginTop: "30px" }}
              >
                Match Horoscope
              </Button> */}
              <Button className="match-kundli-btn"
                variant="contained"
                color="primary" style={{ marginTop: "30px" }} type="submit">Match Horoscope</Button>
            </div>
          </div>
        </form >
      <Typography variant="h6" style={{ marginTop: "30px" }}>
        Saved Matches
      </Typography>
      <Typography variant="body1">
        Please login to check your saved horoscope!
      </Typography>

      <div className="footer">
        <Typography variant="h6" style={{ marginTop: "30px" }}>
          Connect with an Astrologer on Call or Chat for more details.
        </Typography>
      </div>

      {/* Modal to display API data */}
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        data={boyData}
      />
    </div>
  );
};

export default KundliMatch;
