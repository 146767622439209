import React from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom"
import CallIcon from "@mui/icons-material/Call";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import AdjustOutlinedIcon from "@mui/icons-material/AdjustOutlined";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import StarIcon from "@mui/icons-material/Star";
import { Typography } from "@mui/material";
import ArrowBackSharpIcon from "@mui/icons-material/ArrowBackSharp";
import axios from "axios";
import Swal from 'sweetalert2';


const AcharyaPrem = ({showHide}) => {
    const navigate = useNavigate();
    const location = useLocation();

    const astrologerData = location.state?.astrologerData;

    if (!astrologerData) 
    {
        return <div></div>; 
    }
  

    const token = localStorage.getItem("token");
    const totalWalletAmount = localStorage.getItem("totalWalletAmount");
    const fn  = () => {
        if(!token) 
        {
            showHide();
        } else if(totalWalletAmount ==="0" || totalWalletAmount<"10") 
        {
            alert("you have not sufficient balance. Please recharge");
            navigate("/Wallet");
        } else {
            window.location.href = "tel:+91"+astrologerData.contactNo;
        }
    }
   
    return (
        <>
            <div className="main-ajay">
                <div className="acharya-ajay">
                    <Typography
                        variant="h6"
                        fontWeight="700"
                        sx={{ alignitems: "center", padding: "10px" }}
                    >
                        <ArrowBackSharpIcon
                            fontWeight="bold"
                            sx={{
                                fontSize: "20px",
                                marginRight: "10px",
                                alignitems: "center",
                            }}
                            onClick={() => {
                                navigate("/AstrologerCardSearch");
                            }}
                        />
                        {astrologerData.name}
                    </Typography>
                </div>
                <div className="container mt-2">
                    <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-5 col-12 mb-1">
                            <div className="card card12">
                               
                                <img
                                    className="history"
                                    src={`https://app.astrovedaguru.com//${astrologerData.profileImage}`}
                                    alt="Acharya Suman Lata"
                                />
                                
                                <h6>{astrologerData.name}</h6>
                                <p className="experiance">Experience: &nbsp;{astrologerData.experienceInYears} years</p>
                                <p className="languages">Languages: &nbsp;{astrologerData.languageKnown}</p>
                                <p className="expertise">
                                    Expertise in: &nbsp;{astrologerData.primarySkill} </p>
                            
                            </div>
                            <div className="card card13">
                                <div className="rating">
                                    <div className="head">
                                        <h6>Rating</h6>
                                    </div>
                                    <div className="head1">
                                        <StarIcon
                                            sx={{ color: "yellow", marginRight: "5px" }}
                                        />
                                        4.5 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-9 col-lg-9 col-md-8 col-sm-7 col-12 mb-1">
                            <div className="card card14">
                                <div className="row audio-call">
                                    <div className="col-2 call-icon">
                                        <CallIcon
                                            sx={{
                                                fontSize: "40px",
                                                borderRadius: "10px",
                                                marginLeft: "15px",
                                                marginTop: "10px",
                                                backgroundColor: "#4b60bc",
                                                color: "white",
                                            }}
                                        />
                                    </div>
                                    <div className="col-7 audio-rupay">
                                        <p className="para">Audio Call</p>
                                        <p className="para1">
                                            <CurrencyRupeeIcon
                                                sx={{ fontSize: "20px", fontWeight: "700" }}
                                            />
                                            {astrologerData.charge}/min {/* Replace with actual call rate */}
                                        </p>
                                    </div>
                                    <div className="col-3">

                                    {/* <a><button type="button" onClick={fn} class="btn btn-warning call">Call Now</button></a> */}
                                        <button
                                            type="button" style={{backgroundColor:"#d73878"}}
                                            className="btn  call"
                                            onClick={fn}
                                        >
                                            Call Now
                                        </button>
                                    </div>
                                </div>
                                <div className="row unavailable">
                                    <div className="col-2 unavailable-icon">
                                        <EmailOutlinedIcon
                                            sx={{
                                                fontSize: "40px",
                                                borderRadius: "10px",
                                                marginLeft: "15px",
                                                marginTop: "5px",
                                                marginBottom: "5px",
                                                backgroundColor: "#4b60bc",
                                                color: "white",
                                            }}
                                        />
                                    </div>
                                    <div className="col-7 unavailable-rupay">
                                        <p className="para2">Chat Now</p>
                                        <p className="para3">
                                            <CurrencyRupeeIcon
                                                sx={{ fontSize: "20px", fontWeight: "700" }}
                                            />
                                            {astrologerData.charge}/min {/* Replace with actual chat rate */}
                                        </p>{" "}
                                    </div>
                                    <div className="col-3">
                                        <button type="button" className="btn btn-warning call">
                                        {/* onClick={() => window.location.href = 'https://wa.me/8009933066?text=Hello%20there!'} */}
                                            Available
                                        </button>
                                    </div>
                                </div>
                                <div className="row acharya">
                                    <div className="col-6 about-me">
                                        <h6>About Me </h6>
                                        <br></br>
                                        <p>
                                            {astrologerData.loginBio}
                                        </p>
                                    </div>
                                    <div className="col-12 choose">
                                        <h6>Why Choose AstroVedaGuru?</h6>
                                        <Typography variant="p">
                                            <AdjustOutlinedIcon
                                                sx={{
                                                    backgroundColor: "white",
                                                    color: "orange",
                                                    fontSize: "16px",
                                                }}
                                            />
                                            Highly experienced Astrologers
                                        </Typography>
                                        <br></br>
                                        <Typography variant="p">
                                            <AdjustOutlinedIcon
                                                sx={{
                                                    backgroundColor: "white",
                                                    color: "orange",
                                                    fontSize: "16px",
                                                }}
                                            />
                                            100% Secure, Safe & Private
                                        </Typography>
                                        <br></br>
                                        <Typography variant="p">
                                            <AdjustOutlinedIcon
                                                sx={{
                                                    backgroundColor: "white",
                                                    color: "orange",
                                                    fontSize: "16px",
                                                }}
                                            />
                                            Personalized solutions to your problems
                                        </Typography>
                                        <br></br>
                                        <Typography variant="p">
                                            <AdjustOutlinedIcon
                                                sx={{
                                                    backgroundColor: "white",
                                                    color: "orange",
                                                    fontSize: "16px",
                                                }}
                                            />
                                            Get help & support for every important aspect of life
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default AcharyaPrem;


// import React from "react";
// import { useNavigate, useParams } from "react-router-dom";
// import CallIcon from "@mui/icons-material/Call";
// import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
// import AdjustOutlinedIcon from "@mui/icons-material/AdjustOutlined";
// import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
// import StarIcon from "@mui/icons-material/Star";
// import { Typography } from "@mui/material";
// import ArrowBackSharpIcon from "@mui/icons-material/ArrowBackSharp";

// const AcharyaPrem = ({ handleLoginIn }) => {
//   const navigate = useNavigate();
//   const { astrologerName } = useParams();

  
//   const astrologerData = {
//     name: astrologerName,
//     profileImage: 'recordList.profileImage', 
//     experienceInYears: 5, 
//     languageKnown: 'English, Hindi', 
//     primarySkill: 'Astrology, Palmistry', 
//     reportRate: 4.5, 
//     charge: 10, 
//     chatStatus: true, 
//     loginBio: 'Astrologer bio goes here', 
//   };

//   if (!astrologerData) {
//     return <div>Loading...</div>; 
//   }

//   return (
//     <>
//       <div className="main-ajay">
//         <div className="acharya-ajay">
//           <Typography
//             variant="h6"
//             fontWeight="700"
//             sx={{ alignitems: "center", padding: "20px" }}
//           >
//             <ArrowBackSharpIcon
//               fontWeight="bold"
//               sx={{
//                 fontSize: "25px",
//                 marginRight: "10px",
//                 alignitems: "center",
//               }}
//               onClick={() => {
//                 navigate("/ChatAstroSearch1");
//               }}
//             />
//             {astrologerData.name}
//           </Typography>
//         </div>
//         <div className="container mt-4">
//           <div className="row">
//             <div className="col-xl-3 col-lg-3 col-md-4 col-sm-5 col-12 mb-1">
//               <div className="card card12">
//                 <img
//                   className="history"
//                   src={`https://app.astrovedaguru.com/${astrologerData.profileImage}`}
//                   alt="Acharya Suman Lata"
//                 />
//                 <h6>{astrologerData.name}</h6>
//                 <p className="experiance">
//                   Experience: &nbsp;{astrologerData.experienceInYears} years
//                 </p>
//                 <p className="languages">
//                   Languages: &nbsp;{astrologerData.languageKnown}
//                 </p>
//                 <p className="expertise">
//                   Expertise in: &nbsp;{astrologerData.primarySkill}{" "}
//                 </p>
//               </div>
//               <div className="card card13">
//                 <div className="rating">
//                   <div className="head">
//                     <h6>Rating</h6>
//                   </div>
//                   <div className="head1">
//                     <StarIcon sx={{ color: "yellow", marginRight: "5px" }} />
//                     4.5 {/* Replace with actual rating data */}
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="col-xl-9 col-lg-9 col-md-8 col-sm-7 col-12 mb-1">
//               <div className="card card14">
//                 <div className="row audio-call">
//                   <div className="col-2 call-icon">
//                     <CallIcon
//                       sx={{
//                         fontSize: "40px",
//                         borderRadius: "10px",
//                         marginLeft: "15px",
//                         marginTop: "10px",
//                         backgroundColor: "#4b60bc",
//                         color: "white",
//                       }}
//                     />
//                   </div>
//                   <div className="col-7 audio-rupay">
//                     <p className="para">Audio Call</p>
//                     <p className="para1">
//                       <CurrencyRupeeIcon
//                         sx={{ fontSize: "20px", fontWeight: "700" }}
//                       />
//                       {astrologerData.charge}/min 
//                     </p>
//                   </div>
//                   <div className="col-3">
//                     <button
//                       type="button"
//                       className="btn btn-warning call"
//                       onClick={handleLoginIn}
//                     >
//                       Call Now
//                     </button>
//                   </div>
//                 </div>
//                 <div className="row unavailable">
//                   <div className="col-2 unavailable-icon">
//                     <EmailOutlinedIcon
//                       sx={{
//                         fontSize: "40px",
//                         borderRadius: "10px",
//                         marginLeft: "15px",
//                         marginTop: "5px",
//                         marginBottom: "5px",
//                         backgroundColor: "#4b60bc",
//                         color: "white",
//                       }}
//                     />
//                   </div>
//                   <div className="col-7 unavailable-rupay">
//                     <p className="para2">Chat Now</p>
//                     <p className="para3">
//                       <CurrencyRupeeIcon
//                         sx={{ fontSize: "20px", fontWeight: "700" }}
//                       />
//                       {astrologerData.charge}/min 
//                     </p>{" "}
//                   </div>
//                   <div className="col-3">
//                     <button type="button" className="btn btn-warning call">
//                       Unavailable
//                     </button>
//                   </div>
//                 </div>
//                 <div className="row acharya">
//                   <div className="col-6 about-me">
//                     <h6>About Me </h6>
//                     <br></br>
//                     <p>{astrologerData.loginBio}</p>
//                   </div>
//                   <div className="col-6 choose">
//                     <h6>Why Choose astrovedaGuru?</h6>
//                     <Typography variant="p">
//                       <AdjustOutlinedIcon
//                         sx={{
//                           backgroundColor: "white",
//                           color: "orange",
//                           fontSize: "16px",
//                         }}
//                       />
//                       Highly experienced Astrologers
//                     </Typography>
//                     <br></br>
//                     <Typography variant="p">
//                       <AdjustOutlinedIcon
//                         sx={{
//                           backgroundColor: "white",
//                           color: "orange",
//                           fontSize: "16px",
//                         }}
//                       />
//                       100% Secure, Safe & Private
//                     </Typography>
//                     <br></br>
//                     <Typography variant="p">
//                       <AdjustOutlinedIcon
//                         sx={{
//                           backgroundColor: "white",
//                           color: "orange",
//                           fontSize: "16px",
//                         }}
//                       />
//                       Personalized solutions to your problems
//                     </Typography>
//                     <br></br>
//                     <Typography variant="p">
//                       <AdjustOutlinedIcon
//                         sx={{
//                           backgroundColor: "white",
//                           color: "orange",
//                           fontSize: "16px",
//                         }}
//                       />
//                       Get help & support for every important aspect of life
//                     </Typography>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default AcharyaPrem;