
import React, { useEffect, useState } from 'react';
import axios from 'axios';

  const PaymentCallback = () => {
  const [transactionStatus, setTransactionStatus] = useState('');
  const [transactionRef, setTransactionRef] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [walletAmount, setWalletAmount] = useState(() => {
  const storedAmount = localStorage.getItem('totalWalletAmount');
        return storedAmount ? Number(storedAmount) : 0;
    });
    const user_id = localStorage.getItem('user_id');
    const url = 'https://astrovedaguru.com/Wallet';
  useEffect(() => {
    // Check if window.location is available (ensure this runs in the browser)
    if (typeof window !== 'undefined') {
      // Log the URL to see if query params are present
      console.log('Current URL:', window.location.href);

      // Extract query parameters from the URL
      const params = new URLSearchParams(window.location.search);

      const status = params.get('transaction_status');
      const ref = params.get('transaction_ref');
      const order_id = params.get('order_id');
      const amount = params.get('amount');
      

      // Check if both status and ref are available in the query string
      if (status && ref) {
        setTransactionStatus(status);
        setTransactionRef(ref);

        // Optionally, you can send this data to the backend for further processing
        if (status === 'PAYMENT_SUCCESS') 
        {
            console.log('params',params,status,ref);
            const paymentResponse =  axios.post('https://app.astrovedaguru.com/api/addpayment', {
                                amount: amount,
                                paymentMode: 'Phone-Pay',
                                paymentReference: ref,
                                paymentStatus: status,
                                signature: 'test',
                                orderId: order_id,
                                user_id : user_id
                      },
                      {
                          headers: {
                              Authorization: `Bearer ${localStorage.getItem("token")}`
                          }
                      }
                    )

                      
                      .then((response) => {
                        console.log('Payment successfully saved to the backend:', response.data);
                      })
                      .catch((error) => {
                        console.error('Error saving payment response:', error);
                        setError('Error saving payment response');
                      });

                    const updatedWalletAmount= Number(localStorage.getItem('totalWalletAmount'))+Number(amount);
                    localStorage.setItem("totalWalletAmount", updatedWalletAmount);
                    console.log("login amount", updatedWalletAmount)
                    setWalletAmount(updatedWalletAmount);
                    // alert(paymentResponse.data.message);
                    window.location.href = url;
        } else {
            console.log('Payment failed params',params,status,ref);
          setError('Payment failed');
        }
      } else {
        console.log('Missing transaction data  params',params,status,ref);
        setError('Missing transaction data');
      }
    }

    setLoading(false);
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h2>Payment Callback</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <p>Transaction Status: {transactionStatus}</p>
      <p>Transaction Reference: {transactionRef}</p>
    </div>
  );
};

export default PaymentCallback;

