import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Typography } from "@mui/material";
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';
import axios from "axios";
const RashiDetails = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState('');
    const [phoneError, setPhoneError] = useState("");

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        name: '',
        contactNo: '',
        gender: '',
        dob: '',
        tob: '',
        emailAddress: '',
        language: '',
        maritalStatus: '',
        birthPlace: ''
    });

    useEffect(() => {
        const storedData = {
            firstName: localStorage.getItem('firstName') || '',
            lastName: localStorage.getItem('lastName') || '',
            name: localStorage.getItem('userName') || '',
            contactNo: localStorage.getItem('contactNo') || '',
            gender: localStorage.getItem('gender') || '',
            dob: localStorage.getItem('birthDate') || '',
            tob: localStorage.getItem('birthTime') || '',
            emailAddress: localStorage.getItem('email') || '',
            language: localStorage.getItem('language') || '',
            maritalStatus: localStorage.getItem('maritalStatus') || '',
            birthPlace: localStorage.getItem('birthPlace') || ''
        };
        setFormData(storedData);
        setName(storedData.name);
        setEmail(storedData.emailAddress);
        setPhone(storedData.contactNo);
    }, []);


    const rashiDetails = location.state?.rashiDetails;
    const horoscopeSignId = location.state?.horoscopeSignId;
    const submitDetails = () => {
        const data = {
            name: name,
            email: email,
            phone: phone,
            message: message
        };

        axios.post('https://app.astrovedaguru.com/api/sendMail', data)
            .then(response => {
                console.log(response.data);
                alert("Consultation request submitted successfully!");
                setName("");
                setEmail("");
                setPhone("");
                setMessage("");
            })
            .catch(error => {
                console.error('Error submitting consultation:', error);
                alert("Failed to submit consultation request. Please try again later.");
            });
    };

    const handlePhoneChange = (e) => {
        const re = /^[0-9\b]+$/;
        const phoneNumber = e.target.value;

        if (phoneNumber.length === 0 || re.test(phoneNumber)) {
            setPhone(phoneNumber);
            if (phoneNumber.length !== 10) {
                setPhoneError("Please enter a valid 10-digit phone number.");
            } else {
                setPhoneError("");
            }
        }
    };

    const [horoscopeData, setHoroscopeData] = useState([]);

    useEffect(() => {
        if (horoscopeSignId) {
            axios.post('https://app.astrovedaguru.com/api/getDailyHoroscope', { horoscopeSignId })
                .then(response => {
                    setHoroscopeData(response.data.recordList.todayHoroscope);
                })
                .catch(error => {
                    console.error('Error fetching daily horoscope:', error);
                });
        }
    }, [horoscopeSignId]);


    return (
        <>
            <div className="main-contact">
                <div className='acharya-ajay'>
                    <Typography variant='h6' fontWeight="700" sx={{ alignitems: "center", padding: "10px" }}><ArrowBackSharpIcon fontWeight="bold" sx={{ fontSize: "20px", marginRight: "10px", alignItems: "center" }} onClick={() => { navigate("/DailyRashiSearch") }} />{rashiDetails.name}</Typography>
                    <div className="project-1">
                        <Typography className="proheading-1" variant="h4" fontWeight={700} sx={{ marginTop: "-50px", fontFamily: "sen-serif", color: "#d73878", alignContent: "center", justifyContent: "center", alignItems: "center" }}>Today's Horoscope</Typography>
                    </div>
                </div>

                <div className="container mt-4">
                    <div className="row">
                        {/* {horoscopeData && (
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-1 ">
                                {horoscopeData.map(horoscope => (
                                    <div key={horoscope.id} className="card puja-sevash">
                                        <Typography variant='h3' color="#414449" sx={{ marginTop: "15px", fontSize: "18px", fontWeight: "bold", marginLeft: "10px" }}>{horoscope.category}</Typography>
                                        <Typography variant="h3" sx={{ marginTop: "15px", fontSize: "16px", marginLeft: "10px", fontFamily:"sans-serif", color:"GrayText"}} dangerouslySetInnerHTML={{ __html: horoscope.description }} />
                                        <Typography variant="h3" color="#414449" sx={{ fontWeight: "bold", fontSize: "16px", marginLeft: "10px" }}>Percentage: {horoscope.percentage}%</Typography>
                                        <Typography variant="h3" sx={{ marginTop: "15px", fontSize: "25px", fontWeight: "bold" }}></Typography>
                                    </div>
                                ))}
                            </div>
                        )} */}

<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-1 mt-1">
                          <img src='https://app.astrovedaguru.com/public/storage/images/astromallProduct_271721211746.png' alt="loading..." style={{width:"100%", height:"auto", borderRadius:"10px"}} className='shadow-lg' />
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-1 mt-1">
                            <div className="card puja-sevash-1">

                                <Typography variant="p" marginLeft="20px" sx={{ marginTop: "30px" }}>Enter Valid Name</Typography>
                                <input value={name}
                                    onChange={(e) => setName(e.target.value)} className="input-1" type="text" placeholder="Enter your Name" required></input>
                                <Typography variant="p" marginLeft="20px" sx={{ marginTop: "12px" }}>Email (Email is required)*</Typography>
                                <input value={email}
                                    onChange={(e) => setEmail(e.target.value)} className="input-1" type="email" placeholder="Enter your Email" required></input>
                                <Typography variant="p" marginLeft="20px" sx={{ marginTop: "12px" }}>Phone Number*</Typography>
                                <input value={phone}
                                    onChange={handlePhoneChange} className="input-1" type="tel" placeholder="Enter Phone Number" required></input>
                                {phoneError && (
                                    <Typography variant="p" marginLeft="20px" sx={{ color: "red" }}>
                                        {phoneError}
                                    </Typography>
                                )}
                                <textarea value={message}
                                    onChange={(e) => setMessage(e.target.value)} name="message" placeholder="Enter your message" rows="3" className='input-1' required></textarea>
                                <button disabled={phoneError || phone.length !== 10} onClick={submitDetails} className="consultation" sx={{ alignitems: "center", cursor: "pointer" }} >Contact Us </button>
                            </div>
                            <Typography variant="p" marginTop="20px" marginLeft="60px" fontSize="20px">Contact us:<a href="https://www.youtube.com/" style={{ cursor: "pointer", color: "gray" }}><i className="fa fa-youtube-play" style={{ fontSize: "25px", padding: "10px" }}></i></a>
                                <a href="https://www.facebook.com/" style={{ cursor: "pointer", fontWeight: 600, color: "gray" }}><i className="fa fa-facebook" style={{ fontSize: "25px", padding: "10px" }}></i></a>
                                <a href="https://www.instagram.com/" style={{ cursor: "pointer", fontWeight: 600, color: "gray" }}><i className="fa fa-instagram" style={{ fontSize: "25px", padding: "10px" }}></i></a>
                                <a href="https://www.x.com/" style={{ cursor: "pointer", fontWeight: 600, color: "gray" }}><i className="fa fa-twitter" style={{ fontSize: "25px", padding: "10px" }}></i></a></Typography>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RashiDetails