import React from 'react';
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';

const HoroscopeReport = () => {
//     const [cardsData, setCardsData] = useState(astroCard);
//   return (
//     <section className="item-section mt-4 container">
//       <div className="scroll-container">
//         <h4 className="scroll-text">WHY ASTROLOGY</h4>
//       </div>
//       <div className="row talkdata-1">
//         <Cards data={cardsData} className="col-3 mb-2" />
//       </div>
//     </section>
//   );
const navigate = useNavigate();
    
return (
    <>
        <div className='main-policy container mt-3'>
            <div className="acharya-ajay">
                {/* <Typography variant="h6" fontWeight="700" sx={{ alignitems: "center", padding: "10px" }} >
                    <ArrowBackSharpIcon fontWeight="bold" sx={{ fontSize: "20px", marginRight: "10px", alignitems: "center"}} onClick={() => {navigate("/");}}/>
                    WHY ASTROLOGY
                </Typography> */}
            </div>
            <div className='privacy-container-1 container'>
                <div className='privacy-heading'>
                    <Typography variant='h6' fontWeight="700" fontSize={"16px"}>Today's Horoscope</Typography><br></br>

                </div>
                <div className='privacy-heading'>
                    <Typography variant='h1' fontWeight="500" fontSize={"16px"}>Check your today's horoscope
                    </Typography><br></br>

                </div>
                <div>
                <p>

<Typography variant='h6' fontWeight="700" fontSize={"16px"}>Today’s horoscope for zodiac signs</Typography>
Reading your Today’s horoscope is one of the best ways to predict your future. The foretelling of the future through the Daily horoscope is an ancient practice and finds relevance even today. The horoscope prediction is about predicting the individual's future based on various astrological aspects such as the position of the planets, Nakshatras, Tithi, and much more. However, it’s predominantly the movement of planets from one house of the Kundli to another that influences the life of the native and thus his Daily horoscope. As planets are in motion, their position in the chart of the native on a daily basis virtually decides the course of his life and fortune.<br></br><br></br>

Of all the ancient Vedic practices that we are aware of, horoscope reading is one of the most accepted and popular. Horoscope reading transgresses boundaries and is a tea-time read for not just the astrology-loving Indians but also people residing in western countries. In fact, these days there are numerous mediums to bring you your today's horoscope, such as TV, newspapers, magazines, and much more. Whatever the medium, the purpose of the Daily horoscope remains the same, which is to prepare you for life and make you aware of all the upcoming events, so you don’t go blank into life as most people do.<br></br><br></br>

The horoscope you read in newspapers or watch on TV is based on astrology signs, also called zodiac signs. As per Indian astrology, there are 12 zodiac signs namely - Aries, Taurus, Gemini, Cancer, Leo, Virgo, Libra, Scorpio, Sagittarius, Capricorn, Aquarius, and Pisces. Every individual has two zodiac signs. One based on his Sun Sign, and the other based on his Moon Sign. The Sun sign of the person is derived from his date of birth. The Sun as a planet remains in each zodiac sign for as many as 30 days. So whatever sign the Sun was placed in at the time of your birth becomes your Sun sign. Meanwhile, the Moon, on the other hand, changes its position from one zodiac sign to another every 2 to 3 days. So, at the time of your birth, in whatever sign the Moon was placed becomes your Moon Sign. Astrologers prefer reading the Daily horoscope of the native based on his Moon sign.<br></br><br></br>

An astrologer through your horoscope can predict details regarding various aspects of your life, such as love, marriage, career, and much more. The Horoscope today for different zodiac signs can clear the dust of doubt around these aspects that often surround us. In a nutshell, the horoscope for the day can help the native take crucial decisions from the smallest to the biggest things in life. Wondering if it is the best day to begin something new? Look at your Today’s horoscope to find out. Or want to propose love to someone? Look at your Love horoscope before you make the decision. At Astrotalk, we have a team of highly experienced astrologers who work to brew the most authentic daily, weekly, monthly and yearly horoscopes. The free horoscope by astrologers gives you an authentic insight into what your future beholds and how to go for it. Also, just in case the day is not good enough for you as per your chart, the astrologers also recommend astrological remedies to make it better. This way, you are always one step ahead of others in life and can be assured of things you are trying to reach out for.<br></br><br></br></p>
                </div>
                
            </div>
        </div>
    </>
);
};

export default HoroscopeReport;
