
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { dailyData } from "./DailyData";
import axios from "axios";
const DailyFileSearch = () => {
  const [dailysData, setDailyData] = useState(dailyData);
  const navigate = useNavigate();

  const [data1, setData1] = useState([]);
  const [astrologersBlog, setAstrologersBlog] = useState([]);
  useEffect(() => {
    const fetchHoroscopeSigns = async () => {
      try {
        const response = await axios.post('https://app.astrovedaguru.com/api/getHororscopeSign');
        const filteredData = response.data.recordList.filter(item => item.id >= 1 && item.id <= 11).sort((a, b) => a.id - b.id);
        setData1(filteredData);



        
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchHoroscopeSigns();
  }, []);


  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        
        const token = localStorage.getItem("token");
        
        const responseData = await axios.post("https://app.astrovedaguru.com/api/getAppBlog",
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        
        // if (!responseData.ok) 
        // {
        //   console.log('test111111111111111',responseData);
        //   throw new Error('Failed to fetch data');
        // }
        const data = responseData.data;
        // console.log('test',data);
        if (Array.isArray(data.recordList)) 
        {
          const slicedData = data.recordList.slice(0, 6);
          console.log('test',slicedData);
          setAstrologersBlog(slicedData);
        } else {
          console.error('Expected array, received:', data.recordList);
        }
      } catch (error) {
        // console.error('Error fetching data:', error);
      }
    };
    fetchBlogs();
  }, []);
  
  const rashiDetailsClick = (product) => {
    navigate(`/daily-horoscope`, { state: { rashiDetails: product, horoscopeSignId: product.id } });
  };

  return (
    <>

    <div className='container mt-4'>
      <div className="dailyscope">
        <div className="daily-content">
          <h5>Daily Horoscopes</h5>
          <a className="seeyours" style={{ cursor: "pointer", fontWeight: "700", color: " #d73878" }} onClick={() => { (navigate('/DailyRashiSearch')) }}>SEE YOURS<i className="fa fa-chevron-right" style={{ fontSize: "14px", padding: "5px" }}></i></a>
        </div>
        <p>Read and start your day</p>
        <section className="item-section mt-4 container">
          <div className="row talkdata">
            <div className='daily-horoscop'>
              {data1.map((element, index) => (
                <div className="card6" style={{ backgroundColor: "#f8d9e4", cursor: "pointer" }} key={index} onClick={() => rashiDetailsClick(element)}>
                  <div className="card-header">
                    <img src={`https://app.astrovedaguru.com/${element.image}`} className="imdata" alt="..." />
                  </div>
                  <div className="card-body">
                    <h5 className="card-title name">{element.name}</h5>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </div>

    <div className="container mt-4">
        <div className="dailyscope">
          <div className="daily-content">
            <h5>Our Blogs</h5>
          </div>
          <p>1000+ Best Astrologers from India</p>
          <section className="item-section mt-4 container">
            <div className="row talkdata justify-content-center">
            {astrologersBlog.map((blog, index) => (
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-1">
                <iframe
                  height="auto"
                  src={`https://app.astrovedaguru.com/${blog.blogImage}`}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                  style={{borderRadius:"5px"}}
                ></iframe>
              </div>
 ))}
              {/* <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-1">
                <iframe
                  height="auto"
                  src="https://www.youtube.com/embed/d4EYMle_NOM?si=2YCQr3RVHPvWDfrH"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                  style={{borderRadius:"5px"}}
                ></iframe>
              </div>
 
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-1">
                <iframe
                  height="auto"
                  src="https://www.youtube.com/embed/d4EYMle_NOM?si=2YCQr3RVHPvWDfrH"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                  style={{borderRadius:"5px"}}
                ></iframe>
              </div> */}
              
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default DailyFileSearch;