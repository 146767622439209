import "./Style.css";
import React, { useState,useEffect } from "react";
import { astroCard } from "./AstroFile";
import Cards from "./Cards";
const AstroSearch = () => {
  const [cardsData, setCardsData] = useState(astroCard);
  const [astrologersChat, setAstrologersChat] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://app.astrovedaguru.com/api/getSlider', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
           
          },
          body: JSON.stringify({})
        });
        
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        // console.log(data.recordList,'ssssssssssssssssss');
        if (data.recordList) {
          const slicedData = data.recordList.title;
          
          setAstrologersChat(slicedData);
          console.log(data.recordList,'complete');
          
        } else {
          // console.log();
          console.error('Expected array, received:', data.recordList);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);
  return (
    <section className="item-section mt-4 container">
      <div className="scroll-container">
        <h4 className="scroll-text">{astrologersChat}</h4>
      </div>
      <div className="row talkdata-1">
        <Cards data={cardsData} className="col-3 mb-2" />
      </div>
    </section>
  );
};

export default AstroSearch;
